.project-page {
  align-self: flex-start;
  padding-bottom: 30px;

  @media (min-width: 1900px) {
    margin-top: 160px;
    padding-bottom: 70px;
  }

  &__title {
    font-size: 30px;

    @media (min-width: 1360px) {
      font-size: 40px;
    }
  }

  &__text {
    max-width: 750px;
  }
}
