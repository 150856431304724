@font-face {
  font-family: 'FiraSans';
  font-style:  normal;
  font-weight: normal;
  src: local('FiraSansRegular'), url("../fonts/FiraSans-Regular.woff") format("woff");
}

@font-face {
  font-family: 'FiraSans';
  font-style:  normal;
  font-weight: 500;
  src: local('FiraSansMedium'), url("../fonts/FiraSans-Medium.woff") format("woff");
}

@font-face {
  font-family: 'FiraSans';
  font-style:  normal;
  font-weight: 600;
  src: local('FiraSansSemiBold'), url("../fonts/FiraSans-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'PT-Sans';
  font-style:  normal;
  font-weight: normal;
  src: local('PTSans-Regular'), url("../fonts/PTSans-Regular.woff") format("woff");
}

@font-face {
  font-family: 'PT-Sans';
  font-style:  normal;
  font-weight: 700;
  src: local('PTSans-Bold'), url("../fonts/PTSans-Bold.woff") format("woff");
}
