* {

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(255, 245, 238, .1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $first-color;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-resizer {
    display: none;
  }
}
