.hello {
  flex: 1 1 auto;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  align-items:center;
  height: 100%;

  background-color: white;
  color: black;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
  @media (min-width: 1360px) {
    margin-left: 0;
  }
  @media (min-width: 1900px) {
    flex-direction: row;
    align-items: initial;

    height: calc(100% - 170px);
    margin-top: 170px;
  }

  &__content {
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    display: grid;
    grid-template:
      'logo '
      'title'
      'cover'
      'text '
      /1fr
    ;
    grid-gap: 15px;

    @media (min-width: 768px) {
      margin-top: 23px;
      margin-right: 20px;
      padding-left: 60px;
      padding-right: 0;
      padding-bottom: 60px;

      grid-template:
        'logo  cover'
        'title cover'
        'text  cover'
        /1fr   223px
      ;
      grid-gap: 30px;
    }
    @media (min-width: 1900px) {
      margin-right: 60px;
      grid-template:
        'logo  cover'
        'title cover'
        'text  cover'
        /1fr   440px
      ;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
      display: none;
    }
  }

  &__logo {
    grid-area: logo;
    width: 84px;
    height: 88px;

    margin-top: 20px;

    @media (min-width: 1900px) {
      width: 244px;
      height: 256px;

      margin-top: 60px;
      margin-bottom: 70px;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    grid-area: title;
    margin-top: 0;
    margin-bottom: 0;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.2;

    @media (min-width: 1900px) {
      margin-bottom: 30px;
      font-size: 56px;
    }
  }

  &__text {
    grid-area: text;
    margin-top: 0;
    margin-bottom: 0;

    line-height: 1.5;
    font-size: 14px;

    @media (min-width: 1900px) {
      font-size: 20px;
    }
  }

  &__cover {
    grid-area: cover;
    width: 100%;
    max-width: 470px;

    @media (min-width: 1900px) {
      margin-bottom: 20px;
    }
  }

  &__photo {
    width: 100%;
    height: 220px;

    margin-bottom: 10px;

    @media (min-width: 768px) {
      width: 223px;
      height: 300px;
    }
    @media (min-width: 1900px) {
      width: 438px;
      height: 570px;

      margin-bottom: 50px;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      &:first-child {
        display: none;

        @media (min-width: 768px) {
          display: initial;
        }
      }

      &:last-child {

        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  &__name {
    margin-top: 0;
    margin-bottom: 13px;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: #1C1C1C;

    @media (min-width: 1900px) {
      font-size: 28px;
    }
  }

  &__position {
    font-size: 12px;
    font-family: 'FiraSans', Arial, sans-serif;
    line-height: 1.2;
    color: rgba(0, 0, 0, .3);

    @media (min-width: 1900px) {
      font-size: 16px;
    }
  }
}
