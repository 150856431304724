.category {
  width: 100%;
  height: 100%;

  padding-right: 10px;

  border-radius: 5px;
  background-color: rgba(255, 255, 255, .1);

  &.open {

    .category__head::before {
      @include rotateArrow(180);
      transition: transform $transition-second ease-in;
    }

    .category__body {
      display: grid;
    }
  }

  &__head {
    position: relative;
    height: 60px;

    padding: 20px 52px 20px 20px;

    font-size: 20px;

    &::before {
      @include arrow(9px, 1px, 'bottom', $first-color);

      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto 0;

      transition: transform $transition-second ease-in;
    }
  }

  &__body {
    display: none;
    grid-gap: 15px;

    height: calc(100% - 80px);
    overflow: auto;

    padding: 0 20px 40px;

    @for $i from 2 through 3 {
      &_column-#{$i} {
        grid-template-columns: 1fr;

        @media (min-width: 768px) {
          grid-template-columns: repeat($i, 1fr);
        }
      }
    }
  }
}
