.next-banner {
  width: 100%;
  height: 70px;

  display: flex;

  font-size: 14px;

  @media (min-width: 768px) {
    height: 160px;
    font-size: 28px;
  }

  &__before {
    @include center();

    flex: 0 0 auto;

    width: 110px;
    height: 100%;
    padding: 10px;

    background-color: $third-color;
  }

  &__img {
    @include center();

    flex: 1 1 auto;
    height: 100%;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
  }

  &__after {
    display: none;

    @media (min-width: 768px) {
      @include center();

      flex: 0 0 auto;

      width: 180px;
      height: 100%;

      padding: 20px;

      background-color: $third-color;

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;
      }
    }
  }
}
