.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;

  padding-left: $offset;
  padding-right: $offset;

  display: flex;
  align-items: flex-end;

  background-color: transparent;
  border-bottom: 1px solid $first-color;

  transition: background-color $transition-second ease-in;

  @media (min-width: 1360px) {
    padding: 0;
    border-bottom: none;
  }

  &.color {
    background-color: #8B634B;
    transition: background-color $transition-second ease-in;

    .header {

      &__logo {
        margin-top: 15px;
        margin-bottom: 12px;
        transition: margin $transition-second ease-in;

        @media (min-width: 1360px) {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        @media (min-width: 1900px) {
          margin-top: 25px;
          margin-bottom: 15px;
        }
      }

      &__other {
        margin-bottom: 16px;
        transition: margin $transition-second ease-in;

        @media (min-width: 1900px) {
          margin-bottom: 35px;
        }
      }
    }

    .nav {
      border-color: transparent;
      transition: border-color $transition-second ease-in;

      &__item {
        padding-bottom: 35px;
        transition: padding $transition-second ease-in;

        @media (min-width: 1360px) {
          padding-bottom: 17px;
        }
        @media (min-width: 1900px) {
          padding-bottom: 35px;
        }
      }
    }
  }

  &__logo {
    margin: 15px 20px 12px 0;

    @media (min-width: 1360px) {
      margin: 20px 30px 0 20px;
    }
    @media (min-width: 1900px) {
      margin: 50px 80px -10px 60px;
    }
  }

  &__other {
    display: flex;
    align-items:center;

    margin-left: auto;
    margin-bottom: 16px;

    transition: margin $transition-second ease-in;

    @media (min-width: 1360px) {
      margin-bottom: 9px;
    }
  }

  &__search {

    @media (min-width: 1360px) {
      max-width: 140px;
    }
    @media (min-width: 1900px) {
      max-width: initial;
    }
  }

  &__language {
    margin-right: 30px;

    &_desktop {
      display: none;

      @media (min-width: 1360px) {
        display: flex;
      }
    }
  }
}
