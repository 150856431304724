.indicators {
  @include clearList();

  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  &_value-top {
    grid-template-columns: 1fr;
    grid-gap: 20px;

    padding-bottom: 30px;
    border-bottom: 1px solid $first-color;

    @media (min-width: 768px) {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 60px;
    }

    .indicators {

      &__item {
        display: flex;

        @media (min-width: 768px) {
          display: block;
        }
      }

      &__value {
        width: 40%;

        display: flex;
        align-items: flex-end;

        font-size: 30px;
        text-align: right;

        @media (min-width: 768px) {
          width: 100%;
          font-size: 56px;
          text-align: initial;
        }
      }

      &__little {
        margin-left: 10px;
        font-size: 30px;

        @media (min-width: 768px) {
          font-size: 56px;
        }
      }

      &__description {
        width: 60%;
        margin-bottom: 0;

        font-family: 'FiraSans', Arial, sans-serif;
        opacity: .7;

        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__value {
    display: block;
    width: 25%;
    margin-right: 12px;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 38px;
    font-weight: 600;
    text-align: right;

    @media (min-width: 768px) {
      width: 35%;
      font-size: 56px;
    }
    @media (min-width: 1360px) {
      font-size: 80px;
      width: 50%;
    }
  }

  &__little {
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 28px;
    }
    @media (min-width: 1360px) {
      font-size: 40px;
    }
  }

  &__description {
    display: block;
    width: 75%;
    margin-bottom: 5px;

    font-family: 'PT-Sans', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.1;

    @media (min-width: 768px) {
      width: 65%;
      margin-bottom: 10px;
      font-size: 16px;
    }
    @media (min-width: 1360px) {
      width: 50%;
      margin-bottom: 15px;

      font-size: 20px;
    }
  }
}
