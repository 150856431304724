@mixin arrow($width, $border, $direction, $color) {
  width: $width;
  height: $width;

  border-top: $border solid $color;
  border-right: $border solid $color;

  @if $direction == 'top' {transform: rotate(-45deg)}
  @if $direction == 'bottom' {transform: rotate(135deg)}
  @if $direction == 'left' {transform: rotate(-135deg)}
  @if $direction == 'right' {transform: rotate(45deg)}
}

@mixin rotateArrow($deg) {
  transform: rotate(#{$deg}deg - 45deg);
}
