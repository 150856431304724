.card {
  width: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  overflow: hidden;

  text-decoration: none;

  @media (min-width: 768px) {
    height: 268px;
  }

  &:hover {

    .card {

      &__logo {
        background-color: $second-color;
        transition: background-color $transition-second ease-in;
      }

      &__body {
        background-color: $second-color;
        color: $first-color;
        transition: color $transition-second ease-in, background-color $transition-second ease-in;
      }
    }
  }

  &__head {
    flex: 0 0 auto;
    height: 130px;
    display: flex;
  }

  &__logo {
    flex: 0 0 auto;
    width: 162px;
    height: 100%;

    padding: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $third-color;

    transition: background-color $transition-second ease-in;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__img {
    flex: 1 1 auto;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    padding: 20px 25px 25px;

    background-color: white;
    color: $third-color;

    transition: color $transition-second ease-in, background-color $transition-second ease-in;
  }

  &__title {
    margin-top: 0;
    margin-bottom: auto;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
  }

  &__more{
    margin-top: 20px;
    opacity: .5;
  }
}
