.language {
  height: 17px;

  display: flex;
  align-items:center;
  flex-wrap: nowrap;

  &__lng {
    @include clearBtn();
    color: rgba(255, 255, 255, .5);

    &.active {
      color: white;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__separator {
    margin-left: 5px;
    margin-right: 5px;

    color: rgba(255, 255, 255, .5);
  }
}
