.project {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  min-height: 170px;

  border-radius: 8px;
  background-color: white;

  font-family: 'FiraSans', Arial, sans-serif;
  color: #273A73;
  text-decoration: none;

  transition: color $transition-second ease-in, background-color $transition-second ease-in;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  &:hover {
    color: $first-color;
    background-color: #BF2E2E;
    transition: color $transition-second ease-in, background-color $transition-second ease-in;

    .project {

      &__body {
        border-left: 1px solid rgba(255, 245, 238, .5);
        transition: border $transition-second ease-in;
      }

      &__num {
        color: $first-color;
        transition: color $transition-second ease-in;
      }
    }
  }

  &_three-value {
    min-height: 130px;

    .project {

      &__head {
        padding: 24px;

        @media (min-width: 1024px) {
          flex: 0 0 300px;
        }
      }

      &__title {
        font-size: 20px;
      }

      &__body {
        flex: 1 1 auto;

        display: grid;
        align-items:center;
        grid-template-columns: 1fr;
        grid-gap: 10px;

        @media (min-width: 768px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 30px;
        }
      }

      &__cost {

        &:first-child {

          .project__num {
            min-width: 90px;
          }
        }
      }

      &__num {
        min-width: 120px;
        text-align: right;
      }
    }
  }

  &__head {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    padding: 24px 64px 24px 24px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 15px;

    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
    @media (min-width: 1900px) {
      font-size: 24px;
    }
  }

  &__more {
    margin-top: auto;
    opacity: .5;
  }

  &__body {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;

    padding: 25px 30px;
    border-top: 1px solid rgba(39, 58, 115, .5);

    font-size: 16px;
    line-height: 1.1;

    transition: border $transition-second ease-in;

    @media (min-width: 1024px) {
      border-left: 1px solid rgba(39, 58, 115, .5);
      font-size: 20px;
    }

    &_two-value {

      .project {

        &__num {
          min-width: 120px;
          text-align: right;
        }
      }
    }
  }

  &__text {
    margin-bottom: 15px;
  }

  &__num {
    margin-right: 15px;

    font-weight: 600;
    font-size: 50px;
    line-height: 1.5;
    color: #BF2E2E;

    transition: color $transition-second ease-in;

    &_little {
      font-size: 30px;
    }
  }

  &__cost {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (min-width: 1024px) {
      justify-content: initial;
    }
  }

  &__currency {
    margin-bottom: 16px;
  }
}
