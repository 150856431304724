.breadcrumbs {
  font-family: 'FiraSans', Arial, sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, .5);

  & > * {
    margin-right: 7px;

    &:last-child {
      margin-right: 0;
    }
  }

  &_mb {
    margin-bottom: 18px;

    @media (min-width: 1360px) {
      margin-bottom: 35px;
    }
  }

  &__link {
    text-decoration: none;
    color: inherit;

    transition: color $transition-second ease-in;

    &:hover {
      color: rgba(255, 255, 255, .3);
      transition: color $transition-second ease-in;
    }
  }
}
