.staff {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__img {
    flex: 0 0 auto;

    width: 270px;
    height: 270px;

    margin-right: 60px;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 30px;
    }
  }

  &__info-main {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    font-family: 'FiraSans', Arial, sans-serif;
  }

  &__name {
    margin-bottom: 12px;

    font-weight: 500;
    font-size: 24px;
  }

  &__last-name {
    text-transform: uppercase;
  }

  &__position {
    max-width: 300px;
    font-size: 20px;
  }

  &__contacts {
    margin-top: auto;

    display: flex;
    flex-direction: column;
  }

  &__contacts-title {
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
  }
}
