.cluster {
  align-self: flex-start;
  padding-bottom: 30px;

  @media (min-width: 1900px) {
    margin-top: 160px;
    padding-bottom: 70px;
  }

  &__link-back {

    &_to-catalog {
      margin-top: 30px;
      margin-bottom: 0;

      @media (min-width: 1360px) {
        margin-top: 85px;
      }
    }
  }

  &__indicators {
    grid-template-columns: 1fr;
    max-width: initial;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 50px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .indicators {

      &__item {
        justify-content: flex-start;
      }

      &__value {
        width: 30%;

        @media (min-width: 768px) {
          width: auto;
        }
      }

      &__description {
        width: 70%;

        @media (min-width: 768px) {
          width: auto;
        }
      }
    }
  }

  &__project {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    max-width: 770px;
  }

  &__products {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    @media (min-width: 1360px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 70px 40px;

      margin-bottom: 70px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__diagramm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }

  &__graph-circle {
    width: 320px;
    height: 320px;

    @media (min-width: 768px) {
      width: 340px;
      height: 340px;
    }
  }
}
