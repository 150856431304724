.search {
  display: none;
  position: relative;

  width: 215px;
  height: 60px;

  margin-right: 22px;

  @media (min-width: 768px) {
    display: initial;
  }

  &_mob {
    display: block;

    width: 50px;
    height: 50px;
    margin-right: 0;

    @media (min-width: 768px) {
      display: none;
    }

    .search {

      &__input {
        padding-left: 0;
        padding-right: 0;
      }

      &__btn {
        left: 0;
        right: 0;
        margin: auto;

        width: 35px;
        height: 35px;
      }
    }
  }

  &_menu {
    display: block;

    @media (min-width: 1360px) {
      display: none;
    }
  }

  &__input {
    width: 100%;
    height: 100%;

    padding-left: 70px;
    padding-right: 30px;

    border: none;
    outline: none;
    border-radius: 31px;
    background-color: rgba(255, 255, 255, .1);

    font-size: 20px;
    color: white;

    @media(min-width: 1360px) {
      padding-right: 15px;
    }
    @media (min-width: 1900px) {
      padding-right: 30px;
    }

    &::placeholder {
      color: white;

      @media (min-width: 1360px) {
        font-size: 18px;
      }
      @media (min-width: 1900px) {
        font-size: 20px;
      }
    }
  }

  &__btn {
    @include clearBtn();

    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    margin: auto 0;

    width: 43px;
    height: 43px;

    background: url('../svg/icons/search.svg') center/contain no-repeat;
  }
}
