.product {

  &__title {
    margin-top: 0;
    margin-bottom: 15px;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
  }

  &__list {
    margin: 0;
    padding-left: 30px;
  }

  &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $first-color;
    transition: color $transition-second ease-in;

    &:hover {
      color: $second-color;
      transition: color $transition-second ease-in;
    }
  }
}
