.production {
  align-self: flex-start;
  padding-bottom: 30px;

  @media (min-width: 1900px) {
    margin-top: 160px;
    padding-bottom: 70px;
  }

  &__indicators {
    margin-bottom: 40px;
  }

  &__info-wrap {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px;

    @media (min-width: 1900px) {
      grid-template-columns: 1fr 330px;
    }
  }

  &__logo {
    width: 100%;
    height: 130px;

    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: left center;
    }
  }

  &__info {
    font-size: 20px;
    order: -1;

    @media (min-width: 768px) {
      order: initial;
    }
  }

  &__info-block {
    display: flex;
    flex-direction: column;

    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-title {
    margin-bottom: 10px;
    font-weight: 700;
  }

  &__link {
    font-size: 18px;
    text-decoration: none;
    color: $first-color;
  }

  &__contacts {
    font-size: 18px;
    line-height: 1.4;
  }

  &__products {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    @media (min-width: 1360px) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 70px 40px;

      margin-bottom: 70px;
    }
  }

  &__project {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__staff-wrap {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 60px;
    }
    @media (min-width: 1024px) {
      margin-bottom: 90px;
    }
  }

  &__staff {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__diagramm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 2fr 3fr 1fr;
    }
  }
}
