.content {
  width: calc(100% - 40px);
  max-width: 1100px;

  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: 1360px) {
    width: 100%;

    margin-left: 0;
    margin-right: 0;
  }
}
