.subtitle {
  margin-top: 0;
  margin-bottom: 0;
  
  font-family: 'FiraSans', Arial, sans-serif;
  font-size: 28px;
  opacity: .7;

  &_mb {
    margin-bottom: 15px;
  }
}
