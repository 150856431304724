.catalog {
  align-self: flex-start;

  @media (min-width: 1900px) {
    margin-top: 170px;
  }

  &__search-main {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
    @media (min-width: 1360px) {
      margin-bottom: 50px;
    }
  }

  &__result-search {
    margin-bottom: 75px;
  }

  &__list {
    @include clearList();

    display: none;
    margin-bottom: 30px;

    &.active {
      display: block;
    }
  }

  &__item {
    position: relative;
    transition: background-color $transition-second ease-in;

    &::before {
      @include arrow(11px, 1px, 'right', $first-color);

      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;

      @media (min-width: 768px) {
        right: 25px;
      }
    }

    &:last-child {

      .catalog__link {
        border-bottom: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items:center;

    width: 100%;
    height: 100%;

    padding: 15px 15px 15px 0;

    border-bottom: 1px solid $first-color;
    background-color: transparent;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    color: $first-color;

    @media (min-width: 768px) {
      padding: 30px 30px 30px 0;
      font-size: 24px;
    }
    @media (min-width: 1360px) {
      padding: 30px 50px 30px 20px;
      font-size: 30px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      transition: background-color $transition-second ease-in;
    }
  }
}
