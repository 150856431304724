.title-line {

  &_m {
    margin-top: 30px;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  &__title {
    display: block;

    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;

    border-bottom: 1px solid $first-color;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 20px;

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  &__value {
    margin-left: 7px;
  }
}
