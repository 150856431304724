.slider {
  position: relative;
  flex: 1 1 auto;

  max-width: 740px;
  width: 100%;
  height: 200px;

  @media (min-width: 768px) {
    height: 450px;
  }

  &__swiper-container {
    width: 100%;
    height: 100%;
  }

  &__slide {
    position: relative;
    display: flex;
    justify-content: space-between;

    width: 100%;

    background-color: gray;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }
  }

  &__pagination {
    position: absolute;
    bottom: 30px !important;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;

    margin: 0 6px !important;

    border-radius: 100%;
    background-color: #fff;

    outline: none;
  }
}
