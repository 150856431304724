.arrow-up {
  @include clearBtn();
  @include center();

  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 1;

  width: 40px;
  height: 40px;

  background-color: $first-color;
  border-radius: 100%;

  transition: opacity $transition-second ease-in;

  @media (min-width: 768px) {
    bottom: 90px;
    right: 60px;

    width: 70px;
    height: 70px;
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity $transition-second ease-in;
  }

  &__arrow {
    width: 16px;
    height: 22px;

    background: url('../svg/icons/arrow-up.svg') center/contain no-repeat;

    @media (min-width: 768px) {
      width: 22px;
      height: 30px;
    }
  }
}
