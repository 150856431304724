.graph-circle {
  @include center();
  position: relative;

  width: 100%;
  height: 100%;

  &__date {
    position: absolute;
    left: 52%;
  }

  &__round {
    @include center();

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    border-radius: 100%;
    border: 1px solid #746F69;
    clip-path: polygon(0 0, 50% 0, 50% 50%, 100% 50%, 100% 100%, 0 100%);

    &:nth-child(1) {
      width: 100%;
      height: 100%;

      &:hover + .graph-circle__date {
        text-shadow: 0 0 5px #FFFFFF, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
      }

      .graph-circle__round-value {
        clip-path: polygon(0 0, 50% 0, 50% 50%, 100% 80%, 100% 100%, 0 100%);
      }

      & + .graph-circle__date {
        top: -10px;
      }
    }

    &:nth-child(3) {
      width: 90%;
      height: 90%;

      &:hover + .graph-circle__date {
        text-shadow: 0 0 5px #FFFFFF, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
      }

      .graph-circle__round-value {
        clip-path: polygon(0% 0%, 50% 0, 50% 50%, 83% 100%, 0% 100%);
      }

      & + .graph-circle__date {
        top: 8px;
      }
    }

    &:nth-child(5) {
      width: 80%;
      height: 80%;

      &:hover + .graph-circle__date {
        text-shadow: 0 0 5px #FFFFFF, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
      }

      .graph-circle__round-value {
        clip-path: polygon(0% 0%, 50% 0, 50% 50%, 12% 100%, 0% 100%);
      }

      & + .graph-circle__date {
        top: 26px;
      }
    }

    &:nth-child(7) {
      width: 70%;
      height: 70%;

      &:hover + .graph-circle__date {
        text-shadow: 0 0 5px #FFFFFF, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
      }

      .graph-circle__round-value {
        clip-path: polygon(0% 0%, 50% 0, 50% 50%, 0 73%);
      }

      & + .graph-circle__date {
        top: 44px;
      }
    }

    &:nth-child(9) {
      width: 60%;
      height: 60%;

      &:hover + .graph-circle__date {
        text-shadow: 0 0 5px #FFFFFF, 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
      }

      .graph-circle__round-value {
        clip-path: polygon(0% 0%, 50% 0, 50% 50%, 0 50%);
      }

      & + .graph-circle__date {
        top: 62px;
      }
    }
  }

  &__round-value {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    width: 100%;
    height: 100%;

    border: 3px solid #746F69;
    border-radius: 100%;

    transition: border-color $transition-second ease-in;

    &:hover {
      border-color: white;
      transition: border-color $transition-second ease-in;
    }
  }

  &__content {
    @include center();
    flex-direction: column;
  }

  &__value {
    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 600;
    font-size: 56px;
  }

  &__text {
    line-height: 1.1;
    text-align: center;
  }
}
