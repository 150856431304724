.result-search {
  display: none;

  &.active {
    display: block;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    margin-bottom: 30px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;

      margin-bottom: 60px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    @include clearList();
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    position: relative;

    margin-bottom: 20px;
    padding-left: 28px;

    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;

      width: 12px;
      height: 12px;

      background: url('../svg/icons/two-arrow-right.svg') center/contain no-repeat;
    }
  }

  &__link {
    position: relative;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 20px;
    color: #FFF5EE;

    @media (min-width: 1024px) {
      font-size: 30px;
    }
  }
}
