.logo {
  flex: 0 0 auto;
  width: 160px;
  height: 65px;

  background: url('../svg/logo.svg') center/contain no-repeat;

  transition: all $transition-second ease-in;

  @media (min-width: 768px) {
    width: 197px;
    height: 80px;
  }
  @media (min-width: 1900px) {
    width: 265px;
    height: 100px;
  }

  &:hover {
    opacity: .6;
    transition: opacity $transition-second ease-in;
  }
}
