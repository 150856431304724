body {
  min-height: 100vh;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'PT-Sans', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: $first-color;

  &::-webkit-scrollbar-thumb {
    background-color: #1C1C1C;
  }
}

* {
  box-sizing: border-box;
}

main {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100vw;
  min-height: 100vh;
  padding-top: 115px;

  background: url('../images/background.jpg') center/cover no-repeat;

  @media (min-width: 768px) {
    padding-top: 150px;
  }
  @media (min-width: 1360px) {
    flex-direction: row;
    align-items: flex-end;
  }
  @media (min-width: 1900px) {
    padding-top: 0;
  }

  & > * {
    z-index: 1;
  }

  &.blur {
    background: linear-gradient(0deg, rgba(28, 28, 28, .6), rgba(28, 28, 28, .6)), url('../images/background.jpg') center/cover no-repeat;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      backdrop-filter: blur(30px);
      background: rgba(64, 42, 28, .1);
    }
  }
}
