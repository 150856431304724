.aside {
  flex: 0 0 auto;
  width: 100%;
  max-width: 375px;

  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 25px;
  padding-left: 0;

  @media (min-width: 768px) {
    margin-bottom: 35px;
  }
  @media (min-width: 1360px) {
    max-width: 225px;
    margin-bottom: 65px;
  }
  @media (min-width: 1900px) {
    max-width: 375px;
  }

  &__time {
    font-size: 20px;

    @media (min-width: 768px) {
      font-size: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }

  &__date,
  &__day-week {
    font-size: 12px;

    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
}
