.text {
  margin-top: 0;
  margin-bottom: 30px;

  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 1024px) {
    margin-bottom: 50px;
    font-size: 16px;
  }
  @media (min-width: 1360px) {
    font-size: 18px;
  }
  @media (min-width: 1900px) {
    font-size: 20px;
  }

  &_description {
    margin-bottom: 30px;

    @media (min-width: 1024px) {
      margin-bottom: 65px;
    }
  }
}
