.graph {

  &__body {
    padding-top: 50px;
    padding-bottom: 50px;

    display: flex;
    align-items: flex-end;

    &_small {
      max-width: 465px;
      height: 345px;
    }

    &_big {
      max-width: 880px;
      height: 375px;

      margin-bottom: 30px;
    }
  }

  &__column-wrap {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items:center;

    height: 100%;
  }

  &__column {
    @include center();
    cursor: pointer;
    position: relative;

    width: 50%;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), url('../svg/pattern.svg') center/40%;
    border: 1px solid white;

    transition: all, .2s ease-in;

    &:hover {
      background-color: white;
      transition: all, .2s ease-in;
    }
  }

  &__value {
    @include center();
    flex-direction: column;

    position: absolute;
    bottom: calc(100% + 10px);

    width: 1000%;

    span {

      &:first-child {
        font-size: 16px;
        font-weight: 600;

        @media (min-width: 768px) {
          font-size: 30px;
        }
      }

      &:last-child {
        font-size: 10px;

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  &__line {
    position: absolute;
    top: 100%;
    width: 100%;

    margin-top: -1px;
    padding-top: 20px;

    border-top: 1px solid white;

    text-align: center;

    span {
      display: block;
      transform: rotate(45deg);

      @media (min-width: 1024px) {
        transform: rotate(0);
      }
    }
  }

  &__indicators {
    display: flex;
    align-items: flex-end;
  }

  &__indicators-value {
    display: block;
    margin-right: 12px;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 38px;
    font-weight: 600;
    text-align: right;

    @media (min-width: 768px) {
      font-size: 56px;
    }
    @media (min-width: 1360px) {
      font-size: 80px;
    }
  }

  &__indicators-description {
    display: block;
    max-width: 240px;
    margin-bottom: 5px;

    font-family: 'PT-Sans', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.1;

    @media (min-width: 768px) {
      margin-bottom: 10px;
      font-size: 16px;
    }
    @media (min-width: 1360px) {
      margin-bottom: 15px;

      font-size: 20px;
    }
  }
}
