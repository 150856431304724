.title {
  margin-top: 0;
  margin-bottom: 0;

  font-family: 'FiraSans', Arial, sans-serif;
  font-size: 30px;
  font-weight: 500;

  @media (min-width: 1360px) {
    font-size: 56px;
  }

  &_mb {
    margin-bottom: 18px;

    @media (min-width: 1360px) {
      margin-bottom: 45px
    }
  }
}
