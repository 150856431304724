.nav {
  display: none;

  @media (min-width: 1360px) {
    display: initial;
    flex: 1 1 auto;
    max-width: 1100px;

    margin-right: 85px;

    border-bottom: 1px solid white;

    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.2;

    transition: border $transition-second ease-in;

    &__list {
      @include clearList();

      width: 100%;
      display: flex;
    }

    &__item {
      max-width: 115px;

      margin-right: 40px;
      padding-bottom: 17px;

      border-bottom: 5px solid transparent;

      transition: padding $transition-second ease-in;

      @media (min-width: 1360px) {
        margin-right: 25px;
      }
      @media (min-width: 1900px) {
        margin-right: 40px;
      }

      &:first-child {
        max-width: 400px;
        margin-right: auto;
      }

      &:nth-child(2) {
        margin-left: 40px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-color: $first-color;
      }
    }

    &__link {
      width: 100%;

      text-decoration: none;
      color: white;

      transition: opacity $transition-second ease-in;

      &:hover {
        opacity: .6;
        transition: opacity $transition-second ease-in;
      }
    }
  }

  @media (min-width: 1900px) {
    font-size: 18px;
  }
}
