.nav-mobile {

  @media (min-width: 1360px) {
    display: none;
  }

  &__btn {
    @include clearBtn();

    flex: 0 0 auto;

    position: relative;

    width: 25px;
    height: 2px;

    margin-left: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;

    &::before {
      position: absolute;
      content: '';
      top: -7px;
      left: 0;

      width: 100%;
      height: 2px;

      background-color: white;
    }

    &::after {
      position: absolute;
      content: '';
      bottom: -7px;
      left: 0;

      width: 100%;
      height: 2px;

      background-color: white;
    }
  }

  &__wrap {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;

    background-color: rgba(139, 99, 75, .9);

    transform: translateX(100%);
    transition:transform $transition-second ease-in;

    &.open {
      transform: translateX(0);
      transition:transform $transition-second ease-in;
    }
  }

  &__body {
    width: 270px;

    display: flex;
    flex-direction: column;

    padding: 30px 30px 55px;
    background-color: #8B634B;

    @media (min-width: 768px) {
      width: 400px;
    }
  }

  &__top {
    display: flex;
    align-items:center;

    margin-bottom: 75px;
  }

  &__language {
    margin-right: 16px;
  }

  &__search {
    margin-left: auto;
  }

  &__btn-close {
    @include clearBtn();

    position: relative;

    width: 22px;
    height: 22px;

    margin-left: 16px;

    transform: rotate(45deg);

    &::before,
    &::after {
      position: absolute;
      content: '';

      background-color: #fff;
    }

    &::before {
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto 0;

      width: 100%;
      height: 2px;
    }

    &::after {
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;

      width: 2px;
      height: 100%;
    }
  }

  &__list {
    @include clearList();

    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
  }

  &__item {
    margin-bottom: 25px;

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;

      .nav-mobile__link {
        font-size: 18px;
      }
    }

    &.active {

      .nav-mobile__link {
        text-decoration: underline;
      }
    }
  }

  &__link {
    font-family: 'FiraSans', Arial, sans-serif;
    font-size: 22px;
    line-height: 1.2;
    text-decoration: none;
    color: $first-color;
  }
}
