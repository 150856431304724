.search-main {

  &__wrap {
    display: flex;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }
  }

  &__search-line {
    width: calc(100% - 190px);
    position: relative;
    flex: 1 1 auto;

    margin-right: 25px;
    padding: 15px 0 15px 70px;

    border-radius: 31px;
    background-color: rgba(255, 255, 255, .1);

    &::before {
      position: absolute;
      content: '';
      top: 10px;
      left: 20px;

      width: 43px;
      height: 43px;

      background: url('../svg/icons/search.svg') center/contain no-repeat;
    }
  }

  &__input {
    width: 100%;

    padding-right: 30px;

    border: none;
    background-color: transparent;

    font-size: 20px;
    color: $first-color;

    outline: none;
    box-shadow: none;

    &::placeholder {
      font-size: 20px;
      color: rgba(255, 245, 238, .5);
    }
  }

  &__hints {
    display: none;

    margin-left: -50px;
    margin-top: 20px;
    padding-top: 20px;

    border-top: 1px solid rgba(255, 245, 238, .5);

    @media (min-width: 768px) {
      margin-left: initial;
    }

    &.open {
      display: block;
    }
  }

  &__hint {
    display: flex;
    align-items:center;

    margin-bottom: 14px;
    padding-right: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__select {
    @include center();

    margin-left: 5px;
    margin-right: 5px;
    padding: 3px 2px;

    background-color: rgba(255, 245, 238, .2);
    border-radius: 6px;
  }

  &__other {
    @include dotdotdot();
  }

  &__filters-btn {
    @include clearBtn();
    @include center();
    @include dotdotdot();

    position: relative;

    width: 60px;
    height: 60px;

    border-radius: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .4);

    font-size: 20px;
    color: $first-color;

    transition: background-color $transition-second ease-in;

    @media (min-width: 768px) {
      width: 165px;
      padding: 20px 60px 20px 20px;
      border-radius: 31px;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      width: 16px;
      height: 16px;

      background: url('../svg/icons/filter.svg') center/contain no-repeat;

      @media (min-width: 768px) {
        left: initial;
        right: 38px;
        margin: auto 0;
      }
    }

    &.active {
      background-color: rgba(255, 255, 255, .4);
      transition: background-color $transition-second ease-in;
    }

    span {
      display: none;

      @media (min-width: 768px) {
        display: initial;
      }
    }
  }

  &__legend {
    position: relative;

    margin-left: 25px;
    margin-bottom: 25px;
    padding-left: 25px;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &::before {
      left: 0;

      width: 13px;
      height: 13px;

      border-radius: 100%;
      border: 1px solid $first-color;
    }

    &::after {
      left: 5px;

      width: 5px;
      height: 5px;

      background-color: $first-color;
      border-radius: 100%;
    }
  }

  &__filters-wrap {
    display: none;

    &.open {
      display: block;
    }
  }

  &__filters {
    display: grid;
    grid-template:
      'cell-1' auto
      'cell-2' auto
      'cell-3' auto
      /100%
    ;
    grid-gap: 10px;

    @media (min-width: 1024px) {
      grid-template:
        'cell-1 cell-2 cell-2' auto
        'cell-3 cell-3 cell-3' auto
        /1fr    1fr    1fr
      ;
      grid-gap: 30px;
    }

    @for $i from 1 through 3 {
      & > *:nth-child(#{$i}) {
        grid-area: cell-#{$i};
        max-height: 435px;

        @if $i == 3 {max-height: 365px;}
      }
    }
  }
}
