.product-page {
  align-self: flex-start;
  padding-bottom: 30px;

  @media (min-width: 1900px) {
    margin-top: 160px;
    padding-bottom: 70px;
  }

  &__slider {
    margin-bottom: 50px;
  }

  &__text {
    max-width: 750px;
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
