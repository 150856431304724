.link-back {
  position: relative;
  display: block;

  padding-left: 22px;

  font-family: 'FiraSans', Arial, sans-serif;
  font-size: 20px;
  color: inherit;
  text-decoration: none;

  &::before {
    @include arrow(9px, 2px, 'left', $first-color);

    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
  }

  &_mb {
    margin-bottom: 30px;

    @media (min-width: 1360px) {
      margin-bottom: 70px;
    }
  }
}
