.radio {
  cursor: pointer;
  display: flex;

  &__input {
    display: none;

    &:checked + .radio__fake-input {

      &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        width: 5px;
        height: 5px;

        border-radius: 100%;
        background-color: $first-color;
      }
    }
  }

  &__fake-input {
    flex: 0 0 auto;
    cursor: pointer;
    position: relative;
    display: block;

    width: 13px;
    height: 13px;

    margin-top: 5px;
    margin-right: 15px;

    border-radius: 100%;
    border: 1px solid $first-color;
    background-color: transparent;
  }

  &__text {
    font-size: 16px;
    line-height: 1.3;
    color: $first-color;
  }
}
