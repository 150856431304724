.search-page {
  align-self: flex-start;

  @media (min-width: 1900px) {
    margin-top: 170px;
  }

  &__search-main {
    margin-bottom: 50px;
  }

  &__result-search {
    margin-bottom: 75px;
  }
}
