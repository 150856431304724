.content-main {
  display: flex;
  flex-direction: column;

  margin-bottom: 35px;

  @media (min-width: 768px) {
    flex: 1 1 auto;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    flex: initial;
    justify-content: initial;

    margin-bottom: 40px;
    padding-bottom: 60px;

    border-bottom: 1px solid white;
  }
  @media (min-width: 1360px) {
    margin-bottom: 65px;
    padding-bottom: 75px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 20px;

    font-family: 'FiraSans', Arial, sans-serif;
    font-weight: 500;
    font-size: 36px;

    @media (min-width: 768px) {
      margin-bottom: 35px;
      font-size: 50px;
    }
    @media (min-width: 1024px) {
      margin-bottom: 40px;
      font-size: 56px;
    }
    @media (min-width: 1360px) {
      margin-bottom: 45px;
      font-size: 60px;
    }
    @media (min-width: 1360px) {
      margin-bottom: 50px;
      font-size: 80px;
    }
  }
}
